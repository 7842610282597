<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <router-link to="/config" class="back-link router-link-active"><svg data-v-231527a2="" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-231527a2="" d="M7.41 10.58L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.58Z" fill="#0033A0"></path></svg> назад </router-link>

          <h1>Настройка подсистемы «Мониторинг информационных мероприятий»</h1>

          <table class="config-list-table">
            <thead>
            <tr>
              <th>Id конфигурации</th>
              <th>Период действия</th>
              <th>Дата создания</th>
              <th>Дата изменения</th>
              <th>Статус</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="config in configs" :key="config.id">
              <td>{{ config.id }}</td>
              <td>{{ config.startDate | dateFormat }} – {{ config.endDate | dateFormat }}</td>
              <td>{{ config.createdDate | dateFormat }}</td>
              <td>{{ config.modifiedDate | dateFormat }}</td>
              <td v-if="config.active" style="color: darkgreen;">
                Действующая
              </td>
              <td v-else>
                <a href="#" @click.stop="setActive(config.id)">Сделать действующей</a>
              </td>
              <td>
                <div class="edit">
                  <router-link :to="'/infevents/info/'+config.id">
                    <v-icon class="icon-item icon-briefcase-eye-outline"></v-icon>
                  </router-link>
                </div>
                <router-link :to="'/config/infeventsConfigEdit/'+config.id">
                  <div class="edit">
                    <v-icon class="icon-item icon-note-edit-outline"></v-icon>
                  </div>
                </router-link>
              </td>

            </tr>
            </tbody>
          </table>

          <div class="save-buttons">
            <v-btn color="primary" @click="$router.push('/config/infeventsConfigEdit/')">Создать конфигурацию</v-btn>
          </div>

        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>

<script>

export default {
  name: "InfeventsConfig",
  props: {},
  components: {},
  data() {
    return {
      configs: [],
      apiLoaded: false,
      errorText: null,
      apiFreeze: false //уже ушел запрос апи - нужно дождаться его окончания, прежде чем слать новые запросы
    };
  },
  methods: {
    async setActive(configId) {
      if (this.apiFreeze) {
        return
      }
      this.apiFreeze = true
      let req = await this.$postFormDataApi("/infevents/setActiveConfig?id=" + configId)
      if (req.ok) {
        await this.updateData()
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
      this.apiFreeze = false
    },

    async updateData() {
      let req = await this.$getApi("/infevents/getConfigsList")
      if (req.ok) {
        this.configs = req.payload
        this.sortConfigsById()
        this.apiLoaded = true
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    sortConfigsById() {
      this.configs.sort((a, b) => (a.id > b.id) ? 1 : -1)
    }
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  async beforeMount() {
    await this.updateData()
  }
};
</script>
