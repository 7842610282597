import { render, staticRenderFns } from "./InfeventsConfig.vue?vue&type=template&id=2cbb9c88&scoped=true"
import script from "./InfeventsConfig.vue?vue&type=script&lang=js"
export * from "./InfeventsConfig.vue?vue&type=script&lang=js"
import style0 from "./InfeventsConfig.vue?vue&type=style&index=0&id=2cbb9c88&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cbb9c88",
  null
  
)

export default component.exports